
/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #bcd4e6 !important;
  color: #ffffff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
.vvd {
 
    white-space: nowrap; /* Prevents text from wrapping */
    overflow: hidden; /* Ensures text that exceeds button width is hidden */
    text-overflow: ellipsis; /* Adds ellipsis (...) for text overflow */
    display: inline-block; /* Ensures button respects width of its content */
    max-width: 100%; /* Restricts maximum width to its parent's width */
  
  
}
/************ Navbar Css ************/
/* Navbar CSS */
nav.navbar {
  padding: 4px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
  background: linear-gradient(90.21deg, #191970 -5.91%, #00bfff 111.58%);
}
nav.navbar.scrolled {
  padding: 2px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
  width: auto;
}

.logo {
  width: 200px;
  height: auto;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 20px;
  font-size: 16px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping if space is tight */
}

.social-icon {
  display: inline-block;
  margin-left: 10px;
}

.social-icon a {
  width: 36px;
  height: 36px;
  background: rgba(23, 108, 255, 0.3);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 4px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(0, 55, 255, 0.405);
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 10px 20px; /* Adjusted padding for larger buttons */
  margin-top: 2px;
  font-size: 16px;
  margin-left: 12px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

/* Adjustments for medium screens (md) */
@media (max-width: 768px) {
  nav.navbar .navbar-nav .nav-link.navbar-link {
    padding: 0 15px;
    font-size: 14px;
  }

  .navbar-text button {
    padding: 10px 20px; /* Adjusted padding to fit better */
    font-size: 14px;
  }

  .navbar-text {
    flex-direction:column; /* Stack elements vertically on smaller screens */
    align-items: flex-start;
  }

  .social-icon {
    margin-left: 0;
    margin-bottom: 10px; /* Add spacing between social icons and button */
  }
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}


/************ Header Css ************/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
 
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, #191970 -5.91%, #00bfff 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
  color:  #191970;
  
}
.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
  color: #191970;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  color: #191970;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
  color: linear-gradient(90.21deg, #191970 -5.91%, #00bfff 111.58%);
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/************ Skills CSS ************/
.skill {
  padding: 0 0 50px 0;
  position: relative;
}

.skill-bx {
  background:  #0057c94c;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.skill h2 {
  font-size: 45px;
  font-weight: 700;
}

.skill p {
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}

.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}


/************ Projects and Testimonials Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: #bcd4e6;
}
.project h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
  color: #191970;
}
.project p {
  color: #191970;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgba(13, 65, 254, 0.4);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #191970 -5.91%, #00bfff  111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(15, 87, 242, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(15, 87, 242, 0.5);
  border-bottom: 1px solid rgba(15, 87, 242, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(15, 87, 242, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #191970  -5.91%, #00bfff  111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Projects Css ************/
.contact {
  background: linear-gradient(90.21deg, #191970  -5.91%, #00bfff  111.58%);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(217, 219, 224, 0.5);
  border: 1px solid rgba(15, 87, 242, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #191970 ;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background-image: url('./assets/img/footer-bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background:linear-gradient(90.21deg, #63b0a8 -5.91%, #72988e 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background:linear-gradient(90.21deg, #63b0a8 -5.91%, #72988e 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}
.footer img {
  width: 26%;
}
.footer p {

  font-weight: 400;
  font-size: 14px;
  color: #191970;
  letter-spacing: 0.5px;
  margin-top: 20px;
}
/*=================== PAGINATION==================*/

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 0.3s ease-in-out;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #191970; /* Color consistent with existing light grey text color */
  opacity: 1;
  border-radius: 50%;
  margin: 0 4px;
  transition: 0.3s ease-in-out;
}

.swiper-pagination-bullet-active {
  background-color: #191970; /* Color consistent with primary gradient color */
  border: 1px solid #fff; /* Border to make active bullet more prominent */
  transform: scale(1.2); /* Slightly increase size for active bullet */
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
  color: #fff;
}

.swiper-pagination-bullet:hover {
  background-color: #ffffff; /* Match hover state with primary color */
  transform: scale(1.1); /* Slightly increase size on hover */
}

.container.testimonials__container {
  width: 40%;
  padding-bottom: 4rem;
  position: relative;
}

.client__avatar {
  width: 4rem;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 1rem;
  border: 0.4px solid #191970 111.58%;
  display: flex;
  justify-content: center;
}

.testimonial {
  background: linear-gradient(90.21deg, #191970 -5.91%, #00bfff  111.58%);
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
  user-select: none;
}

.client__review {
  color: #191970 111.58%;
  font-weight: 300;
  display: block;
  width: 80%;
  margin: 0.8rem auto 0;
}

@media (max-width: 756px) {
  .navbar-text .btn.btn-sm {
    padding: 10px 10px; /* Adjust padding as needed */
    font-size: 14px; /* Adjust font size as needed */
  }
}
/* Media Queries */
@media screen and (max-width: 1024px) {
  .container.testimonials__container{
      width: 60%;
      }
 

}


@media screen and (max-width: 600px) {
  .container.testimonials__container{
      width: var(--container-width-sm);
      }
      .client__review {
          width: var(--container-width-sm);
      }

  

}

/* Payments CSS */

.payments-section {
  margin-bottom: 80px;
  padding: 80px 0;
  background-color: #0057c94c;
  text-align: center;
  border-radius: 2%;
}

.section-title {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 50px;
  color: #fff;
}

.payment-card {
  margin-bottom: 30px;
}

.payment-icon {
  font-size: 50px;
  color: #191970;
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
}

.payment-icon:hover {
  transform: scale(1.1);
}


/* Details CSS */


/* App.css */


/************ Project Details Css ************/
/************ Project Details Css ************/
/************ Project Details Css ************/
.project-details {
  padding: 80px 0;
  position: relative;
  background-color: #bcd4e6 ;
  color: #191970;
}

.project-details h1, .project-details h2 {
  margin-top: 100px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
}
.project-details h1 {
  font-size: 45px;
}

.project-details h2 {
  font-size: 35px;
  margin-top: 40px;
}

.project-details .intro-text {
  color: #191970;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin-bottom: 60px;
}



.project-details .project-image {
  max-width: 60%;  /* Reduces the maximum width */
  height: auto;
  border-radius: 20px;
  margin: 0 auto 20px auto;
  display: block;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.project-details .project-description {
  color: #191970;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  text-align: center;
  margin: 0 auto 40px auto;
}

@media (max-width: 768px) {
  .project-details .project-image {
    max-width: 90%;  /* Adjusts the width for smaller screens */
  }

  .project-details h1 {
    font-size: 35px;  /* Adjusts the heading size for smaller screens */
  }

  .project-details h2 {
    font-size: 30px;  /* Adjusts the subheading size for smaller screens */
  }

  .project-details .intro-text,
  .project-details .project-description {
    font-size: 16px;  /* Adjusts the text size for smaller screens */
  }
}

@media (max-width: 480px) {
  .project-details h1 {
    font-size: 30px;  /* Further adjusts the heading size for very small screens */
  }

  .project-details h2 {
    font-size: 25px;  /* Further adjusts the subheading size for very small screens */
  }

  .project-details .intro-text,
  .project-details .project-description {
    font-size: 14px;  /* Further adjusts the text size for very small screens */
  }
}


/************ Careers Css ************/

.careers {
  position: relative;
  background-color: #bcd4e6;
  color: #fff;
  margin-top: 150px;
  
}

.hero-section {
  position: relative;
  text-align: center;
  overflow: hidden;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  

 
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.5);
  transition: transform 0.3s ease-in-out;
}

.hero-section:hover .hero-image {
  transform: scale(1.05);
}

.hero-text {
  position: absolute;
  color: #fff;
  text-align: center;
  z-index: 2;
  margin-top: 50px;
  margin-bottom: 90px;
}

.hero-text h1 {
  margin-top: 100px;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 20px;
  animation: fadeInUp 1s ease-in-out;
}

.hero-text .intro-text {
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  animation: fadeInUp 1.5s ease-in-out;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.intro-section {

  padding: 60px 0;
  background-color: #191970;
  border-bottom: 1px solid #191970;
}

.intro-section h2 {
  
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 40px;
}

.job-listing {
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  padding: 20px;
  background: linear-gradient(90.21deg, #191970 -5.91%, #00bfff 111.58%);

  border-radius: 10px;
  transition: background-color 0.3s ease-in-out;
  animation: fadeInUp 2s ease-in-out;
}

.job-listing:hover {
  background-color: #bcd4e6;
}

.job-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.job-details h3 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 10px;
}

.job-details p {
  font-size: 18px;
  color: #f7f7f7 ;
  letter-spacing: 0.8px;
  line-height: 1.5em;
}

@media (max-width: 768px) {
  .hero-section 
  {
    height: 300px;
  }

  .hero-text h1 
  {
    font-size: 35px;
  }

  .hero-text .intro-text 
  {
    font-size: 18px;
  }

  .job-listing {
    flex-direction: column;
    text-align: center;
  }

  .job-image {
    margin-bottom: 20px;
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .hero-section {
    height: 250px;
  }
  

  .hero-text h1 {
    font-size: 30px;
  }

  .hero-text .intro-text {
    font-size: 16px;
  }

  .intro-section h2 {
    font-size: 30px;
  }

  .job-details h3 {
    font-size: 24px;
  }

  .job-details p {
    font-size: 16px;
  }
}


/* General button styles */
.ret-button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background-color: #191970 ;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Hover and focus states */
.ret-button:hover,
.ret-button:focus {
  background-color: #191970 ;
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(22, 86, 247, 0.396);
}

/* Active state */
.ret-button:active {
  background-color: #191970 ;
  transform: translateY(0);
  box-shadow: 0 2px 10px rgba(22, 86, 247, 0.396);
}

/* Button with gradient */
.ret-button-gradient {
  background: linear-gradient(45deg, #191970, #00bfff);
}

.ret-button-gradient:hover, .ret-button-gradient:focus {
  background: linear-gradient(45deg, #191970, #00bfff);
}

/* Button with shadow */
.ret-button-shadow {
  box-shadow: 0 8px 30px rgba(22, 86, 247, 0.396);
}

.ret-button-shadow:hover, .ret-button-shadow:focus {
  box-shadow: 0 12px 40px rgba(22, 86, 247, 0.396)
}

/* Animated button */
.ret-button-animated {
  position: relative;
  overflow: hidden;
}

.ret-button-animated::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(22, 86, 247, 0.396);
  transition: left 0.4s ease;
}

.ret-button-animated:hover::before {
  left: 100%;
}

/* Large buttons */
.ret-button-large {
  padding: 16px 32px;
  font-size: 18px;
}

/* Small buttons */
.ret-button-small {
  padding: 8px 16px;
  font-size: 14px;
}

/* Button with rounded corners */
.ret-button-rounded {
  border-radius: 50px;
}


.job-application {
 
  background: #bcd4e6;
  padding: 60px 0 200px 0;
}

.job-application img {
  width: 92%;
  margin-bottom: 30px; /* Added margin to separate image from content */
  border-radius: 15%;
  margin-top: 100px;
}

.job-application h2 {

  margin-top: 150px;
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
  color:  #191970 /* Ensuring text color contrasts with the background */
}

.job-application form input, 
.job-application form textarea,
.job-application form select {
  width: 100%;
  background: rgba(254, 254, 254, 0.396);
  border: 1px solid rgba(22, 86, 247, 0.396);
  border-radius: 20px;
  color:  #191970;
  margin-bottom: 15px; /* Added margin for spacing between fields */
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.job-application form input:focus, 
.job-application form textarea:focus,
.job-application form select:focus {
  background: rgba(255, 255, 255, 1);
  color: #191970;
}

.job-application form input::placeholder, 
.job-application form textarea::placeholder,
.job-application form select option {
  font-size: 16px;
  font-weight: 400;
  color:  #191970;
}

.job-application form input:focus::placeholder, 
.job-application form textarea:focus::placeholder {
  color: #191970;
  opacity: 0.8;
}

.job-application form button {
  font-weight: 700;
  color:  #191970;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}

.job-application form button:hover {
  color:  #191970;;
}

.job-application form button::before {
  content: "";
  background: #fefeff;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.job-application form button:hover::before {
  width: 100%;
}

.return-button {
  margin-left: 15px; /* Adjusted margin for the return button */
}

.job-application form .form-group {
  margin-bottom: 20px; /* Added margin for form groups */
}


.text-custom-color {
  color: #191970;
}
.white-button {
  background-color: white;
  color: blue;
  border: 1px solid blue;
  padding: 10px 20px;
  cursor: pointer;
}

.white-button:hover {
  background-color: #f0f0f0;
}

.logof {
  width: 50%; /* Adjust the width as needed for a larger logo */
  height: auto; /* This keeps the aspect ratio */

}

.services {
  padding: 60px 0;
  background-color: #bcd4e6;
}

.services h2 {
  font-size: 28px;
  font-weight: 600;
  color: #191970;
  margin-bottom: 40px;
}

.service-box {
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 30px 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;

}

.service-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.service-icon img {
  max-width: 80px;
  margin-bottom: 20px;
  color: #191970;
}

.service-box h5 {
  font-size: 18px;
  font-weight: 600;
  color:  #191970;
  margin-bottom: 15px;
}

.service-box p {
  font-size: 14px;
  color: #666;
}

@media (min-width: 768px) {
  .services h2 {
    font-size: 32px;
  }

  .service-box h5 {
    font-size: 20px;
  }

  .service-box p {
    font-size: 16px;
  }
}
